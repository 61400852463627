import emitter from '/~/core/emitter'
import ui from '/~/core/ui'
import { usePayrollOrder } from '/~/composables/batch-order/payroll'
import { FlowType } from '/~/composables/checkout/checkout-types'
import router from '/~/router'
import { BatchOrderStatusHandler } from '../core/BatchOrderStatusHandler'

const { setPayrollOrder, syncBatchOrderPayees } = usePayrollOrder()

const routes: Record<string, string> = {
  payees_synced: 'payroll-payment-pending-payees',
  payee_pending: 'payroll-payment-pending-payees',
  payee_not_supported: 'payroll-payment-unsupported-payees-detected',
}

const batchOrderAllStatuses = [
  'new',
  'file_processing',
  'file_processed',
  'payees_syncing',
  'payees_synced',
  'payee_not_found',
  'payee_not_supported',
  'payee_pending',
]

const payrollOrderStatusHandler = new BatchOrderStatusHandler(
  { url: '/v3/batch-orders', statuses: batchOrderAllStatuses, type: 'payroll' },
  goToPageByStatus,
  goToCheckout,
  syncPayees
)

function syncPayees() {
  setPayrollOrder(payrollOrderStatusHandler.batchOrder)
  syncBatchOrderPayees()
}

function goToPageByStatus(status: string) {
  if (routes[status] && ui.desktop) {
    setPayrollOrder(payrollOrderStatusHandler.batchOrder)
    router.push({
      name: routes[status],
    })
  } else {
    router.push({ name: 'payroll-upload' })
  }
}

function goToCheckout() {
  router.push({
    name: 'payments-make-payroll',
    params: {
      orderId: payrollOrderStatusHandler.batchOrder.number,
    },
  })
}

emitter.on('notifications:batch-order', async (event) => {
  const { batchOrderId, batchOrderStatus, notification } = event

  if (document.visibilityState === 'hidden') {
    return
  }

  if (payrollOrderStatusHandler.batchOrder.fileUploadId !== batchOrderId) {
    return
  }

  if (router?.history?.current.meta.flowType !== FlowType.payroll) {
    return
  }

  if (batchOrderStatus === 'process_failed') {
    emitter.emit(
      'batch-order-creation-failed',
      'There was an error creating an order. Please try again.'
    )
  }

  if (batchOrderStatus === 'process_cancelled') {
    emitter.emit('batch-order-creation-cancelled', notification.title)
  }

  await payrollOrderStatusHandler.check()
})

export function usePayrollOrderStatusHandler() {
  return {
    payrollOrderStatusHandler,
  }
}
