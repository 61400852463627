import Vue, { computed, reactive, readonly, ref } from 'vue'
import { ApiResponseData, BatchOrder as BatchOrderRawData } from '/~/types/api'
import api from '/~/core/api'
import modal from '/~/core/mdl'
import { BatchOrder, BatchOrderType } from '/~/composables/batch-order'
import { usePoints } from '/~/composables/points'
import router from '/~/router'

const { calculatePointsEarnedForPayment } = usePoints()

const payrollOrder = reactive(new BatchOrder())
const batchOrderPointsToEarn = computed(() =>
  calculatePointsEarnedForPayment(payrollOrder.subtotal)
)
const isBatchOrderLoading = ref(false)

const isPayeesSyncedBatchOrder = computed(() =>
  ['payees_synced'].includes(payrollOrder.status)
)

function setBatchOrderLoading(isLoading: boolean) {
  isBatchOrderLoading.value = isLoading
}

function setPayrollOrder(orderData: BatchOrderRawData | undefined = undefined) {
  Object.assign(payrollOrder, new BatchOrder(orderData))
}

async function syncBatchOrderPayees() {
  setBatchOrderLoading(true)

  try {
    await api.post(
      `/v3/batch-orders/${payrollOrder.number}/transactions/sync-payees/`
    )
  } catch (error) {
    console.error(error)
  }
}

async function fetchBatchOrder(
  batchOrderNumber: string,
  options: {
    useLoading: boolean
  } = {
    useLoading: true,
  }
) {
  if (options.useLoading) {
    setBatchOrderLoading(true)
  }

  const response = await api.get<ApiResponseData<BatchOrderRawData>>(
    `/v3/batch-orders/${batchOrderNumber}`
  )

  setPayrollOrder(response.data)

  if (options.useLoading) {
    setBatchOrderLoading(false)
  }

  return response.data
}

async function cancelBatchOrder() {
  setBatchOrderLoading(true)

  await api.put(`/v3/batch-orders/${payrollOrder.number}`, {
    status: 'cancelled',
  })

  setPayrollOrder()
  setBatchOrderLoading(false)
}

async function cancelOrder() {
  try {
    await cancelBatchOrder()
    router.push({
      name: 'payroll-upload',
    })
  } catch (error) {
    Vue.notify({
      type: 'error',
      text: 'There was an error cancelling an order. Please try again.',
    })
  }
}

function onCancelBatchOrder(modalProps = { onConfirm: async () => {} }) {
  modal.show('confirm-v3', {
    props: {
      title: 'Cancel payment',
      description:
        'Cancelling will navigate you away from the current payment process. Are you sure you want to proceed?',
      width: 'xse',
      onConfirm: async () => {
        await cancelOrder()
        await modalProps.onConfirm?.()
      },
    },
  })
}

function onRemoveBatchFile(modalProps = { onConfirm: async () => {} }) {
  modal.show('confirm-v3', {
    props: {
      title: 'Remove file',
      description: 'Are you sure you want to remove the file?',
      width: 'xs',
      onConfirm: async () => {
        await cancelOrder()
        await modalProps.onConfirm?.()
      },
    },
  })
}

async function createPayrollOrder(payload: { fileUploadId: string }) {
  setBatchOrderLoading(true)

  try {
    const response = await api.post<ApiResponseData<BatchOrderRawData>>(
      '/v3/batch-orders',
      {
        ...payload,
        type: BatchOrderType.payroll,
      }
    )

    if (response) {
      setPayrollOrder(response.data)
    }
  } finally {
    setBatchOrderLoading(false)
  }
}

export function usePayrollOrder() {
  return {
    payrollOrder: readonly(payrollOrder),
    batchOrderPointsToEarn,
    isBatchOrderLoading: readonly(isBatchOrderLoading),
    setBatchOrderLoading,
    setPayrollOrder,
    fetchBatchOrder,
    cancelBatchOrder,
    cancelOrder,
    onCancelBatchOrder,
    onRemoveBatchFile,
    syncBatchOrderPayees,
    isPayeesSyncedBatchOrder,
    createPayrollOrder,
  }
}
